<template>
  <div>
    <v-card elevation="0" v-if="bio.length > 0">
      <v-row>
        <v-col md="4" sm="12">
          <v-card elevation="0">
            <div class="d-flex justify-center">
              <v-avatar size="120">
                <v-img
                  v-if="ProfilePicture !== ''"
                  :src="ProfilePicture"
                  height="120px"
                  class="rounded"
                  contain
                ></v-img>

                <v-img
                  :src="user.avatar"
                  height="100px"
                  class="rounded"
                  contain
                  v-else-if="user.avatar !== null"
                />

                <v-img
                  src="../../../../assets/profile.jpg"
                  height="100px"
                  class="rounded"
                  contain
                  v-else
                />
              </v-avatar>
            </div>

            <v-card-subtitle v-if="editProfilePic" class="text-center">
              Attach a passport Size colored photo
            </v-card-subtitle>

            <v-card-actions>
              <v-file-input
                outlined
                dense
                ref="Image"
                type="file"
                v-model="formData.Image"
                v-if="editProfilePic"
                @change="attachProfilePicture"
                accept="image/png, image/jpeg"
                append-icon="mdi-paperclip"
              >
                <template v-slot:label>
                  <div>Attach Photo <span class="red--text">*</span></div>
                </template>
              </v-file-input>
              <v-btn
                text
                color="primary"
                class="text-capitalize"
                block
                v-if="!editProfilePic"
                @click="editProfilePic = true"
              >
                Update Profile Picture
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col md="8" sm="12">
          <v-form v-model="isValid" ref="bioForm">
            <v-text-field
              outlined
              dense
              v-model="formData.firstName"
              :rules="rules.Field"
              ref="FirstName"
              placeholder="First Name"
              persistent-placeholder
            >
              <template v-slot:label>
                <div>First Name <span class="red--text">*</span></div>
              </template>
            </v-text-field>

            <v-text-field
              outlined
              dense
              v-model="formData.secondName"
              persistent-placeholder
              placeholder="Middle Name"
              ref="SecondName"
            >
              <template v-slot:label>
                <div>Middle Names <span class="red--text">*</span></div>
              </template>
            </v-text-field>

            <v-text-field
              outlined
              dense
              v-model="formData.lastName"
              :rules="rules.Field"
              persistent-placeholder
              placeholder="Last Name"
              ref="LastName"
            >
              <template v-slot:label>
                <div>Last Name <span class="red--text">*</span></div>
              </template>
            </v-text-field>

            <v-row class="mt-n3">
              <v-col md="6" cols="12">
                <v-select
                  dense
                  outlined
                  :items="['Male', 'Female', 'Other']"
                  v-model="formData.gender"
                  :rules="rules.genderSelectField"
                  ref="Gender"
                >
                  <template v-slot:label>
                    <div>Gender <span class="red--text">*</span></div>
                  </template>
                </v-select>
              </v-col>
              <v-col md="6" cols="12">
                <v-text-field
                  outlined
                  dense
                  type="date"
                  v-model="formData.dateOfBirth"
                  :rules="rules.Field"
                  ref="DateOfBirth"
                >
                  <template v-slot:label>
                    <div>Date of Birth <span class="red--text">*</span></div>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <ContactDialog ref="contactDialog" />
        </v-col>
      </v-row>
    </v-card>
    <v-card flat v-else>
      <v-row>
        <v-col md="4" sm="12" cols="12">
          <v-skeleton-loader type="avatar" />
        </v-col>
        <v-col md="8" sm="12" cols="12">
          <v-skeleton-loader v-for="n in 7" :key="n" type="text" />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { AuthService } from "../../../auth";
import ContactDialog from "../contact/ContactDialog.vue";

export default {
  name: "BioDialog",
  components: { ContactDialog },
  data: function () {
    return {
      image_url: "",
      editable: true,
      edit: false,
      isValid: false,
      formData: {
        Image: undefined,
        email: "",
        firstName: "",
        lastName: "",
        nationality: "KE",
        profileID: "",
        secondName: "",
        gender: "",
        dateOfBirth: "",
        base64ProfilePic: undefined,
      },
      getImageinbs64: "",
      editProfilePic: false,
    };
  },

  mounted() {
    this.$store.dispatch("Profile/getBio");
    this.$store.dispatch("Profile/getContact");
  },

  computed: {
    rules() {
      return {
        profImage: [
          (v) => {
            return this.editable &&
              AuthService.user.profile &&
              !AuthService.user.profile.picture
              ? !!v || "Profile Picture is required"
              : true;
          },
          (v) => {
            return this.editable && !!v
              ? v.size < 10000000 || "Attachment should be less than 10MB"
              : true;
          },
          (v) => {
            return this.editable && !!v
              ? v.type.includes("image") || "Attachment must be an image"
              : true;
          },
        ],
        Field: [(v) => !!v || "Field is required"],
        genderSelectField: [(v) => !!v || "Gender is required"],
      };
    },
    user() {
      return AuthService.user;
    },
    bio() {
      return this.$store.getters["Profile/bioGetters"]("bio");
    },
    contact() {
      return this.$store.getters["Profile/contactGetters"]("contact");
    },
    ProfilePicture() {
      let profile = this.bio[0];

      if (this.image_url) return this.image_url;

      return profile
        ? profile.base64ProfilePic
          ? `data:image/jpeg;base64,${profile.base64ProfilePic}` ||
            `data:image/png;base64,${profile.base64ProfilePic}`
          : ""
        : "";
    },

    profImage() {
      return `data:image/jpeg;base64,${this.bio[0].base64ProfilePic}`;
    },
  },

  methods: {
    saveProfile: function () {
      if (!this.isValid) {
        this.$refs.bioForm.validate();
      } else {
        const data = { ...this.formData };
        data.Image = data.base64ProfilePic;
        data.profileID = this.user.profileID;
        this.$store.dispatch("Profile/saveBio", data);
        this.$refs.contactDialog.saveContact();
      }
    },
    convertTobase64: async function (url) {
      const data = await fetch(url);
      const blob = await data.blob();
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => {
        const base64data = reader.result;
        console.log(base64data);
        const strImage = base64data.replace(/^data:image\/[a-z]+;base64,/, "");
        this.formData.base64ProfilePic = strImage;
        return base64data;
      };
    },
    attachProfilePicture: function () {
      this["image_url"] = URL.createObjectURL(this.formData["Image"]);
      this.getImageinbs64 = this.convertTobase64(this.image_url);
    },
    redirect() {
      this.$router.push({
        name: "Home",
      });
    },
    editProfile: function () {
      if (!this.isValid) {
        this.$refs.bioForm.validate();
        this.$refs.contactDialog.$refs.contactForm.validate();
      } else {
        if (!this.$refs.contactDialog.$refs.contactForm.validate()) {
          return;
        } else {
          const data = { ...this.formData };
          data.Image = data.base64ProfilePic;
          data.profileID = this.user.profileID;
          this.$store.dispatch("Profile/saveBio", data);
          this.$refs.contactDialog.updateProfileContact();
        }
      }
    },
    checkBio() {
      if (this.bio.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },

  watch: {
    bio: {
      handler: function () {
        if (this.bio.length > 0) {
          this.formData = { ...this.bio[0] };
          if (this.formData.firstName === "" && this.user.firstName !== "") {
            this.formData.firstName = this.user.firstName;
            this.formData.lastName = this.user.lastName;
          }
          if (this.formData.dateOfBirth === "0001-01-01") {
            this.formData.dateOfBirth = "";
          }
          if (this.formData.gender.length <= 1) {
            this.formData.gender = "";
          }
        }
      },
    },
  },
};
</script>

<style scoped>
.transform {
  text-transform: uppercase;
}
</style>
