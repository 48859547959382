<template>
  <v-card flat>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="referees"
        dense
        class="elevation-0"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title class="text-h6">My Referees</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              color="primary"
              dark
              small
              class="mb-2"
              @click="openDialog()"
            >
              Add Referee
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" color="blue" @click="update(item)">
            mdi-pencil
          </v-icon>
          <v-icon small color="red" @click="remove(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-col>

    <RefereesCard
      :referreeDialog="referreeDialog"
      @close-dialog="closeDialog"
      :refereeDetails="refereeDetails"
      ref="childRef"
    />
  </v-card>
</template>

<script>
import RefereesCard from "./RefereesCard.vue";
export default {
  name: "Referee",
  components: { RefereesCard },
  mounted() {
    this.$store.dispatch("Profile/getReferee");

    Event.$on("redirectToReferee", () => {
      this.referreeDialog = false;
    });
  },
  data: () => ({
    referreeDialog: false,
    edit: false,
    refereeDetails: {},
    formData: {
      name: "",
      address: "",
      eMail: "",
      phoneNo: "",
      placeOfWork: "",
      designation: "",
    },
    searchForm: {
      search: "",
    },
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "name",
      },

      { text: "Place of Work", value: "placeOfWork" },
      { text: "Designation", value: "designation" },

      // { text: "status", value: "status" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),

  computed: {
    referees() {
      return this.$store.getters["Profile/refereeGetters"]("referees");
    },
  },

  methods: {
    update: function (val) {
      this.referreeDialog = true;
      this.refereeDetails = val;
    },
    remove(val) {
      this.$store.dispatch("Profile/deleteReferee", val);
    },
    NumbersOnly(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    closeDialog() {
      this.referreeDialog = false;
    },
    openDialog() {
      this.referreeDialog = true;

      this.$nextTick(() => {
        this.$refs.childRef.$refs.refForm.reset();
      });
    },
  },
};
</script>

<style scoped></style>
