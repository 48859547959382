<template>
  <v-container>
    <v-card class="elevation-1">
      <v-card-title class="document-title">
        <v-btn text small class="deep-orange" @click="$router.back()">
          <v-icon left> mdi-arrow-left </v-icon>
          Go Back
        </v-btn>
        <v-btn
          small
          color="primary"
          class="mx-2"
          @click="downloadFile(base64, document.applicationNo)"
        >
          <v-icon left color="white"> mdi-download </v-icon>
          Download
        </v-btn>
        <v-spacer />
        <v-btn
          class="deep-orange white--text mx-2 text-capitalize"
          small
          @click="commentsDialog = true"
        >
          <v-icon>mdi-close</v-icon>
          Reject Job Offer
        </v-btn>
        <v-btn
          class="indigo white--text mx-2 text-capitalize"
          small
          @click="negotiateOffer"
        >
          Negotiate Job Offer
        </v-btn>
        <v-btn class="primary mx-2 text-capitalize" @click="acceptOffer" small>
          <v-icon>mdi-check</v-icon>
          Accept Job Offer
        </v-btn>
        <v-chip
          :color="getStatusColor(document.offerStatus)"
          class="mx-2 white--text"
          >Status: {{ document.offerStatus }}</v-chip
        >
      </v-card-title>
      <v-divider />

      <v-row>
        <v-col cols="8" offset="2">
          <v-card-text class="elevation-1 my-1">
            <pdf
              v-for="i in pageCount(base64)"
              :key="i"
              :src="`data:application/pdf;base64,${base64}`"
              :page="i"
            />
          </v-card-text>
        </v-col>
      </v-row>
      <v-dialog v-model="commentsDialog" width="50%">
        <v-card class="mx-auto">
          <v-card-title>
            Rejection Comments
            <v-spacer />
            <v-btn icon @click="commentsDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form ref="commentForm" v-model="isValid">
              <v-textarea
                v-model="comments"
                label="Comments"
                placeholder="Enter comments here"
                outlined
                rows="5"
                :rules="[(v) => !!v || 'Comments is required']"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="error" text @click="commentsDialog = false">
              Cancel
            </v-btn>
            <v-spacer />
            <v-btn color="primary" @click="rejectOffer">
              Reject Job Offer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import pdf from "vue-pdf";
import FileMixin from "@/mixin/FileMixin";
import AuthService from "@/modules/auth/authService";
import { helper } from "@/utils";
export default {
  name: "JobOfferDocument",
  mixins: [FileMixin],
  components: { pdf },
  data: function () {
    return {
      numPages: undefined,
      dialog: true,
      commentsDialog: false,
      comments: "",
      isValid: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Home/getJobOffers", to.params.code);
    });
  },
  computed: {
    document: function () {
      return this.$store.getters["Home/homeGetters"]("jobOffers");
    },
    user() {
      return AuthService.user;
    },
    base64: function () {
      return this.document ? this.document.offerLetter : "";
    },
  },
  methods: {
    getStatusColor(status) {
      return helper.status(status);
    },
    rejectOffer() {
      if (!this.isValid) {
        this.$refs.commentForm.validate();
      } else {
        const data = {
          vacancyID: this.document.vacancyID,
          profileID: this.document.profileID,
          applicationNo: this.document.applicationNo,
          offerNo: this.document.offerNo,
          offerStatus: "Declined",
          comments: this.comments,
          // signature: this.user.name
        };

        this.commentsDialog = false;

        this.$confirm.show({
          title: "Confirm Rejection",
          text: "Are you sure you want to reject this job offer?",
          onConfirm: () => {
            this.$store.dispatch("Home/patchJobOffer", { ...data });
          },
        });
      }
    },
    acceptOffer() {
      const data = {
        vacancyID: this.document.vacancyID,
        profileID: this.document.profileID,
        applicationNo: this.document.applicationNo,
        offerNo: this.document.offerNo,
        offerStatus: "Accepted",
        // signature: this.user.name,
        signature: "",
      };
      this.$confirm.show({
        title: "Confirm Acceptance",
        text: "confirm acceptance of this job offer",
        onConfirm: () => {
          this.$store.dispatch("Home/patchJobOffer", { ...data });
        },
      });
    },
    negotiateOffer() {
      const data = {
        vacancyID: this.document.vacancyID,
        profileID: this.document.profileID,
        applicationNo: this.document.applicationNo,
        offerNo: this.document.offerNo,
        offerStatus: "Negotiation",
        // signature: this.user.name
      };

      this.$confirm.show({
        title: "Confirm Negotiation",
        text: "confirm negotiation of this job offer",
        input: true,
        label: "Enter your comments here",
        onConfirm: (comments) => {
          this.$store.dispatch("Home/patchJobOffer", {
            ...data,
            comments: comments,
          });
        },
      });
    },
  },
};
</script>
<style>
.document-title {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}
</style>
