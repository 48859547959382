<template>
  <v-container>
    <v-card v-if="!isEmpty()" class="mt-n2" flat>
      <v-card-title class="mb-0 pb-0" v-if="isApply">
        <v-spacer />
        <v-btn
          v-if="!checkIfJobIsApplied()"
          small
          class="mx-1"
          :to="{
            name: 'Job',
            params: {
              jobid: specificJob.jobID,
              vacancyid: specificJob.code,
            },
          }"
        >
          View Job
        </v-btn>
        <v-btn
          v-if="!checkIfJobIsApplied()"
          class="mx-1"
          color="primary"
          small
          @click="setjobStorage()"
        >
          Apply
        </v-btn>
        <v-card-text class="mt-n3 mb-n5" v-if="checkIfJobIsApplied()">
          <p class="">
            <span class="red--text text-subtitle-2 font-italic caption"
              ><v-icon color="red" small>mdi-alert</v-icon>You have already
              applied to this position !</span
            >
          </p>
          <v-col justify="center"> </v-col>
        </v-card-text>
      </v-card-title>

      <v-card-title>{{
        specificJob ? specificJob.fullTitle : "N/A"
      }}</v-card-title>

      <v-card-text>
        <v-row no-gutters class="caption">
          <!-- <v-col cols="12" md="6">Type: Full-time</v-col> -->
          <!-- <v-col cols="12" md="6">Position:{{ specificJob.fullTitle }}</v-col> -->
          <v-col cols="12" md="6"
            >Location:
            <span class="black--text font-italic font-weight-black ml-1">
              {{ specificJob.location }}
            </span></v-col
          >
          <v-col cols="12" md="6"
            >Status:<span
              class="green--text font-italic font-weight-black caption ml-1"
            >
              {{ getStatus(specificJob.status) }}</span
            ></v-col
          >
          <v-col cols="12" md="12"
            >Application Deadline:<span
              :class="
                diffInDays() <= 5
                  ? 'red--text font-italic font-weight-black caption ml-1'
                  : 'green--text font-italic font-weight-black caption ml-1'
              "
            >
              {{ formatDate(specificJob.deadlineDate) }}</span
            ></v-col
          >
          <v-col
            cols="12"
            md="12"
            class="mt-2"
            v-if="specificJob.showPayRange === true"
            >Salary: KES
            {{
              specificJob ? specificJob.minAskingSalary : "" | currencyFormat
            }}-{{
              specificJob ? specificJob.maxAskingSalary : "" | currencyFormat
            }}
            / {{ specificJob ? specificJob.payFrequency : "" }}</v-col
          >
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-text v-if="specificJob.description !== ''">
        <!-- <h3 class="subtitle-1">Job description</h3> -->

        <h3 class="black--text font-subtitle-1 font-weight-medium">
          Job description
        </h3>

        <p class="caption text-justify">
          {{ specificJob ? specificJob.description : "" }}
        </p>
      </v-card-text>

      <v-card-text
        v-if="specificJob.vacancyResponsibilities.length !== 0"
        class="mt-n4"
      >
        <!-- <h4 class="subtitle-2">Job Responsibilities</h4> -->
        <h4 class="black--text font-subtitle-1 font-weight-medium">
          Job Responsibilities
        </h4>
        <ul
          class="caption"
          v-for="resp in specificJob.vacancyResponsibilities"
          :key="resp.lineNo"
        >
          <li>{{ resp.description }}&nbsp;</li>
        </ul>
      </v-card-text>

      <v-card-text
        v-if="specificJob.vacancyQualifications.length !== 0"
        class="mt-n4"
      >
        <!-- <h4 class="subtitle-2">Job Requirements</h4> -->
        <h4 class="black--text font-subtitle-1 font-weight-medium">
          Job Requirements
        </h4>

        <ul class="caption">
          <li
            v-for="req in specificJob.vacancyQualifications"
            :key="req.lineNo"
          >
            {{ req.type }}- {{ req.qualificationDescription }}
            <!-- <p>{{ req.qualificationCode }} -{{ req.minQualification }}</p> -->
          </li>
        </ul>
      </v-card-text>
      <v-card-text
        v-if="specificJob.vacancyAttributes.length !== 0"
        class="mt-n4"
      >
        <h4 class="subtitle-2">Personal Attributes</h4>

        <ul class="caption">
          <li v-for="i in specificJob.vacancyAttributes" :key="i.code">
            <p>{{ i.description }}</p>
          </li>
        </ul>
      </v-card-text>
    </v-card>
    <v-card flat>
      <v-skeleton-loader
        v-if="isEmpty()"
        type="card-heading, list-item-two-line, list-item-two-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line"
      ></v-skeleton-loader>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "selectedJobCard",
  props: {
    specificJob: {
      type: Object,
      default: () => {},
    },
    isApply: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      departments: [],
    };
  },
  computed: {
    jobs: function () {
      return this.$store.getters["Home/homeGetters"]("jobs");
    },
    applications: function () {
      return this.$store.getters["Home/homeGetters"]("applications");
    },
  },

  methods: {
    buttonClick: function () {
      alert("button click");
    },

    listClick: function () {
      alert("List click");
    },
    checkUndefined() {
      if (this.specificJob === undefined) {
        return this.jobs[0];
      }
    },
    isEmpty() {
      return this.specificJob === undefined;
    },
    getStatus(val) {
      if (val === "Open") {
        return "Ongoing";
      } else if (val === "Published") {
        return "Active";
      } else {
        return val;
      }
    },
    setjobStorage() {
      this.$router.push({
        name: "Check",
        params: {
          jobid: this.specificJob.jobID,
          vacancyid: this.specificJob.code,
        },
      });
      window.localStorage.setItem(
        "jobNumber",
        JSON.stringify({
          jobid: this.specificJob.jobID,
          vacancyid: this.specificJob.code,
        })
      );
    },
    formatDate(date) {
      if (date === "0001-01-01") return "N/A";
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      const val = [year, month, day].join("-");

      var today = new Date(val);
      var m = today.toLocaleString("default", { month: "short" });

      return [year, m, day].join("-");
    },
    diffInDays() {
      if (this.specificJob.deadlineDate === "0001-01-01") return "N/A";
      const date = new Date();

      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      // This arrangement can be altered based on how we want the date's format to appear.
      const currentDate = `${year}-${month}-${day}`;
      const dt1 = new Date(currentDate);
      const dt2 = new Date(this.specificJob.deadlineDate);
      return Math.floor(
        (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
          Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
          (1000 * 60 * 60 * 24)
      );
    },
    checkIfJobIsApplied: function () {
      if (
        this.applications.length !== 0 &&
        this.applications.find((d) => d.jobPosting === this.specificJob.code)
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped></style>
