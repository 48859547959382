<!-- eslint-disable no-extra-boolean-cast -->
<template>
  <v-card elevation="0">
    <v-card-title> Contacts </v-card-title>
    <v-form v-model="isValid" ref="contactForm" class="mt-n4">
      <v-card-text class="">
        <v-row dense>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="formData.eMail"
              :rules="rules.Field"
              ref="eMail"
              dense
              outlined
            >
              <template v-slot:label>
                <div>Email <span class="red--text">*</span></div>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4"
            ><v-text-field
              v-model="formData.phoneNo"
              :rules="rules.Field"
              ref="phoneNo"
              type="text"
              v-on:keypress="NumbersOnly"
              dense
              outlined
            >
              <template v-slot:label>
                <div>Phone <span class="red--text">*</span></div>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4"
            ><v-text-field
              v-model="formData.phoneNo2"
              type="text"
              v-on:keypress="NumbersOnly"
              ref="phoneNo2"
              dense
              outlined
            >
              <template v-slot:label>
                <div>Alternative Contact</div>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4"
            ><v-text-field
              v-model="formData.address"
              :rules="rules.Field"
              ref="address"
              dense
              outlined
            >
              <template v-slot:label>
                <div>Address <span class="red--text">*</span></div>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4"
            ><v-text-field
              v-model="formData.placeOfWork"
              :rules="rules.Field"
              ref="placeOfWork"
              type="text"
              dense
              outlined
            >
              <template v-slot:label>
                <div>Current Location <span class="red--text">*</span></div>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              v-model="formData.location"
              :rules="rules.Field"
              ref="location"
              dense
              outlined
            >
              <template v-slot:label>
                <div>P.O. Box <span class="red--text">*</span></div>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
    <Spinner />
  </v-card>
</template>

<script>
import { AuthService } from "../../../auth";
import Spinner from "@/plugins/loader/views/Spinner";

export default {
  name: "Contact",
  components: { Spinner },

  data() {
    return {
      type: "",
      edit: true,
      isValid: false,
      formData: {
        profileID: "",
        phoneNo: "",
        eMail: "",
        address: "",
        placeOfWork: "",
        designation: "",
        address2: "",
        phoneNo2: "",
        location: "",
        city: "",
        type: "Personal",
        countryCode: "KE",
      },
    };
  },

  computed: {
    rules() {
      return {
        Field: [(v) => !!v || "Field is required"],
      };
    },
    user() {
      return AuthService.user;
    },

    contact() {
      return this.$store.getters["Profile/contactGetters"]("contact");
    },
  },

  methods: {
    saveContact: function () {
      if (!this.isValid) {
        this.$refs.contactForm.validate();
      } else {
        delete this.formData.status;
        this.formData.profileID = this.user.profileID;
        this.$store.dispatch("Profile/saveContact", this.formData);
      }
    },
    checkContact() {
      if (this.contact.length > 0) {
        return true;
      } else {
        return false;
      }
    },

    NumbersOnly(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    editContact() {
      if (!this.isValid) {
        this.$refs.contactForm.validate();
      } else {
        this.formData.profileID = this.user.profileID;
        delete this.lastModifiedTime;
        this.$store.dispatch("Profile/editContact", this.formData);
      }
    },
    updateProfileContact() {
      if (this.formData.versionNo) {
        this.editContact();
      } else {
        this.saveContact();
      }
    },
    redirect() {
      this.$router.push({
        name: "Home",
      });
    },
  },

  watch: {
    contact: {
      handler: function () {
        if (this.contact.length > 0) {
          const contact = { ...this.contact[0] };
          Object.keys(this.formData).forEach((key) => {
            if (contact[key] === contact[key]) {
              this.formData[key] = contact[key];
            }
          });
          this.formData.versionNo = contact.versionNo;
          this.formData.entryNo = contact.entryNo;
        }
        if (this.contact.length === 0) {
          const phoneStr = this.formData.phoneNo;
          if (/^[A-Za-z0-9]*$/.test(phoneStr)) {
            this.formData.phoneNo = "";
            this.formData.eMail = this.user.email;
          } else {
            this.formData.phoneNo = this.user.phone;
            this.formData.eMail = this.user.email;
          }
        }
      },
    },
  },
};
</script>

<style scoped></style>
<!--  -->
