<template>
  <v-container class="fill-height">
    <v-card-text>
      <v-skeleton-loader
        type="card-heading, list-item-two-line, list-item-two-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line"
      ></v-skeleton-loader
    ></v-card-text>

    <v-dialog v-model="dialog" max-width="75%" persistent>
      <v-card-title class="profile--title">
        Update Profile
        <v-spacer />
        <v-btn
          class="deep-orange lighten-4 deep-orange--text text--darken-4"
          elevation="0"
          @click="$router.back()"
        >
          <v-icon class="mx-2">mdi-close</v-icon>
          Cancel
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-tabs v-model="tab" active-class="orange lighten-5">
        <!-- tabs -->
        <v-tab> Bio </v-tab>
        <v-tab> Academic Qualifications </v-tab>
        <v-tab> Work Experience </v-tab>
        <v-tab> Referree </v-tab>
        <!-- items -->
        <v-tab-item> <Bio ref="bioDialog" /> </v-tab-item>
        <v-tab-item> <academics /> </v-tab-item>
        <v-tab-item> <experience /> </v-tab-item>
        <v-tab-item> <Referees /> </v-tab-item>
      </v-tabs>
      <v-card-actions class="application--card-actions">
        <v-spacer></v-spacer>
        <v-btn v-if="tab !== 0" class="mx-2" @click="tab--">
          <v-icon class="mx-2">mdi-arrow-left</v-icon> Back
        </v-btn>
        <v-btn
          class="primary text-capitalize"
          v-if="tab !== 3"
          @click="nextStep()"
        >
          Next <v-icon class="mx-2">mdi-arrow-right</v-icon></v-btn
        >
        <!-- <v-btn color="primary" v-if="tab === 2" @click="Submit()">
          Submit <v-icon class="mx-2">mdi-arrow-right</v-icon></v-btn
        > -->
      </v-card-actions>
    </v-dialog>
  </v-container>
</template>

<script>
import Bio from "./bio/Bio";
import academics from "./academic/AcademicList.vue";
import experience from "./experience/Experience.vue";
import { AuthService } from "../../auth";
import Referees from "./referees/Referees.vue";

export default {
  name: "updateProfile",
  components: { Bio, academics, experience, Referees },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Profile/getBio");
      v.$store.dispatch("Profile/getMaritalStatus");
      v.$store.dispatch("Profile/getTitles");
      v.$store.dispatch("Profile/getSectors");
      v.$store.dispatch("Profile/getContact");
    });
  },

  data: function () {
    return {
      tab: 0,
      edit: false,
      dialog: true,
      formData: {
        LastName: "",
        FirstName: "",
        SecondName: "",
        Gender: "",
        Title: "",
        DateOfBirth: "",
        IDImage: undefined,
        ID: "",
        Nationality: "KE",
        Discipline: "",
        Disability: false,
        DisabilityDescription: "",
        Image: undefined,
      },
      rules: {
        required: [(value) => !!value || "Required."],
      },
      editable: false,
      headers: [{ title: "Profile", icon: "mdi-account" }],
    };
  },

  mounted() {
    Event.$on("updatedProfile", () => {
      this.dialog = false;
    });
    Event.$on("goNextStep", () => {
      console.log("go to the next step");
      this.tab++;
    });
  },

  computed: {
    setDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("close-dialog", val);
      },
    },
    user() {
      return AuthService.user;
    },
  },

  methods: {
    nextStep: function () {
      if (this.tab === 0) {
        !this.$refs.bioDialog.checkBio()
          ? this.$refs.bioDialog.saveProfile()
          : this.$refs.bioDialog.editProfile();
      } else {
        this.tab++;
      }
    },
  },
};
</script>

<style scoped>
.v-input__append-outer {
  margin-top: 8px;
}
</style>
