<template>
  <v-card>
    <h3 class="black--text font-subtitle-1 font-weight-medium">
      Please Wait as we Process your Request ......
    </h3>
    <Spinner />
  </v-card>
</template>

<script>
import { AuthService } from "../../auth";
import Spinner from "@/plugins/loader/views/Spinner";

export default {
  name: "linkedInCheck",
  components: { Spinner },

  data: () => ({
    Doc: undefined,
    dialog: true,
  }),

  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (v.$route.query) {
        const data = JSON.parse(atob(v.$route.query.user));
        const jobNo = JSON.parse(window.localStorage.getItem("jobNumber"));
        if (jobNo) {
          AuthService.autoLogin(data.token, data.user).then(function () {
            v.$router.push({
              name: "uploadCV",
              params: {
                vacancyid: jobNo.vacancyid,
                jobid: jobNo.jobid,
              },
            });
          });
        } else {
          AuthService.autoLogin(data.token, data.user).then(function () {
            v.$router.push({
              name: "Home",
            });
          });
        }
      }
    });
  },

  computed: {},

  methods: {},
};
</script>

<style scoped></style>
