<template>
  <v-container class="mt-5">
    <v-data-table
      :headers="headers"
      :items="qualifications"
      dense
      class="elevation-0"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="text-h6">Academics</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>

          <v-btn
            outlined
            color="primary"
            dark
            small
            @click="openDialog()"
            class="mb-2"
          >
            Add Academic
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" color="blue" @click="update(item)">
          mdi-pencil
        </v-icon>
        <v-icon small color="red" @click="remove(item)"> mdi-delete</v-icon>
      </template>
    </v-data-table>

    <AcademicCard
      :academicDialog="academicDialog"
      @close-dialog="closeDialog"
      :academicDetails="academicDetails"
      ref="childRef"
    />
  </v-container>
</template>

<script>
import AcademicCard from "./AcademicCard.vue";
export default {
  name: "AcademicList",
  components: { AcademicCard },

  data() {
    return {
      type: "",
      edit: false,
      editable: true,
      academicDialog: false,
      academicDetails: {},
      headers: [
        {
          text: "Institution",
          align: "start",
          sortable: false,
          value: "institution",
        },

        { text: "Specialization", value: "areaOfSpecialization" },
        { text: "Qualification", value: "type" },

        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  mounted() {
    this.$store.dispatch("Profile/getAcademic");
  },

  computed: {
    qualifications() {
      return this.$store.getters["Profile/academicGetters"]("academic");
    },
  },

  methods: {
    openDialog() {
      this.academicDialog = true;
      this.$nextTick(() => {
        this.$refs.childRef.$refs.academicForm.reset();
      });
    },
    update: function (val) {
      this.academicDialog = true;
      this.academicDetails = val;
    },
    closeDialog: function (val) {
      this.academicDialog = val;
    },
    remove: function (val) {
      //Todo Confirmation
      this.$store.dispatch("Profile/removeAcademic", { ...val });
    },
  },
};
</script>

<style scoped></style>
<!--  -->
