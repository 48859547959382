<template>
  <v-dialog persistent v-model="dialog" max-width="50%">
    <v-card>
      <v-card-title class="primary">
        <span class="text-h5 font-weight-black">
          {{ params ? "Edit" : "Add" }} Attachment
        </span>
      </v-card-title>
      <p
        class="red--text font-italic caption pa-3"
        v-if="mandatoryDocs.length !== 0"
      >
        <v-icon color="red" small>mdi-alert</v-icon>
        Mandatory Documents

        <v-chip-group column v-model="departments">
          <v-chip
            x-small
            label
            v-for="doc in mandatoryDocs"
            :key="doc.entryNo"
            color="primary"
            class="ml-4 mt-n1"
          >
            {{ doc.description }}
          </v-chip>
        </v-chip-group>
      </p>
      <p
        v-if="mandatoryDocs.length === 0"
        class="blue--text font-italic caption pa-3"
      >
        * No Mandatory Attachmants for this Job Vacancy
      </p>

      <v-form v-model="isValid" ref="attachForm" class="mt-n8">
        <v-card-text class="mt-n2">
          <v-container>
            <v-row dense>
              <v-col cols="12">
                <v-autocomplete
                  dense
                  outlined
                  :items="attachmentDocTypes"
                  v-model="formData.documentCode"
                  ref="Type"
                  :item-value="(item) => item.code"
                  :item-text="(item) => item.description"
                  :rules="rules.Field"
                  prepend-icon="mdi-file"
                >
                  <template v-slot:label>
                    <div>
                      Type
                      <span class="red--text">*</span>
                    </div>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12">
                <v-file-input
                  accept="application/pdf"
                  :rules="rules.Field"
                  dense
                  prepend-icon="mdi-paperclip"
                  outlined
                  persistent-placeholder
                  placeholder="Attach Document"
                  @change="uploadFile"
                  v-model="Doc"
                  ><template v-slot:label>
                    <div>
                      Attachment
                      <span class="red--text">*</span>
                    </div>
                  </template></v-file-input
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text></v-form
      >

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="redirect()"> Close </v-btn>
        <v-btn color="primary" @click="params ? Edit() : Save()">
          {{ params ? "Edit" : "Save" }}</v-btn
        >
      </v-card-actions>
    </v-card>
    <Spinner />
  </v-dialog>
</template>

<script>
import { helper } from "../../../../utils";
import { AuthService } from "../../../auth";
import Spinner from "@/plugins/loader/views/Spinner";

export default {
  name: "AttachmentCard",
  components: { Spinner },

  data: () => ({
    formData: {
      documentCode: "",
      relatedRecordId: "",
      attachment: "",
    },
    isValid: false,
    Doc: undefined,
    dialog: true,
    departments: [],
  }),

  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (v.$route.params.line) {
        v.$store.dispatch("Profile/getOneAttachment", v.$route.params.line);
      }
      v.$store.dispatch("Profile/getAttachmentDocTypes");
      v.$store.dispatch("Home/getSingleJob", v.$route.params.code);
    });
  },

  mounted() {
    Event.$on("redirectToAttachment", () => {
      this.$router.push({
        name: "Attachments",
      });
    });
  },

  computed: {
    rules() {
      return {
        Field: [(v) => !!v || "Field is required"],
        attachment: [
          (v) => !!v || "Attachment is required",

          (v) =>
            (!!v && v.size < 10000000) || "Attachment should be less than 10MB",

          (v) =>
            (!!v && v.type.includes("application/pdf")) ||
            "Attachment must be a Pdf",
        ],
      };
    },

    attachmentDocTypes() {
      return this.$store.getters["Profile/attachmentGetters"](
        "attachmentDocTypes"
      );
    },

    bio() {
      return this.$store.getters["Profile/bioGetters"]("bio");
    },
    user() {
      return AuthService.user;
    },
    attachmentDetails() {
      return this.$store.getters["Profile/attachmentGetters"](
        "singleAttachment"
      );
    },
    params() {
      return this.$route.params.line;
    },
    singleJob: function () {
      const singleJob = this.$store.getters["Home/homeGetters"]("singleJob");
      return singleJob ? singleJob : {};
    },
    mandatoryDocs: function () {
      return Object.keys(this.singleJob).length !== 0
        ? this.singleJob.mandatoryDocs
        : [];
    },
    application: function () {
      return this.$store.getters["Profile/attachmentGetters"]("application");
    },
  },

  methods: {
    redirect() {
      this.$router.push({
        name: "Attachments",
      });
    },
    Save: function () {
      if (!this.isValid) {
        this.$refs.attachForm.validate();
      } else {
        this.formData.relatedRecordId = this.bio[0].id;
        const data = {
          attachData: helper.prepareFormData({ ...this.formData }),
          profileID: this.user.profileID,
        };
        if (this.application) {
          data.stage = this.application.shortlistingStage;
        }
        this.$store.dispatch(`Profile/saveAttachment`, data);
      }
    },
    uploadFile: async function (value) {
      if (value) {
        this.formData.attachment = value;
      }
    },

    Edit: function () {
      this.formData.relatedRecordId = this.bio[0].id;
      const patchData = {
        id: this.formData.id,
        attachment: this.formData.attachment,
      };
      delete this.formData.id;
      const data = {
        attachData: helper.prepareFormData({ ...patchData }),
        profileID: this.user.profileID,
      };
      this.$store.dispatch(`Profile/editAttachment`, data);
    },
  },

  watch: {
    attachmentDetails() {
      this.formData = { ...this.attachmentDetails };
    },
  },
};
</script>

<style scoped></style>
