<template>
  <v-container class="pb-10">
    <div class="d-flex flex-column mt-7">
      <Logo class="align-self-center py-10" />
    </div>
    <v-card elevation="10" class="mx-auto py-3 loginCard">
      <div v-if="isAuthenticated" class="text-center">
        <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <template v-else>
        <v-card-actions class="d-flex flex-column">
          <v-btn href="/auth" color="primary" block>Go Back</v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-container>
</template>

<script>
import Logo from "../../../components/Logo.vue";
export default {
  name: "SocialLoginCallback",
  components: {
    Logo,
  },
  mounted() {
    if (this.$route.query.code) {
      return this.$store.dispatch("Auth/SocialLoginCallback", {
        code: this.$route.query.code,
        provider: "linkedin-openid",
      });
    }
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters["Auth/isAuthenticated"];
    },
  }
};
</script>

<style lang="scss" scoped></style>
