<template>
  <v-card elevation="0" class="px-2">
    <v-card-text class="mt-4">
      <v-row>
        <v-col md="4" sm="12">
          <v-card elevation="0">
            <div class="d-flex justify-center">
              <v-avatar size="140">
                <v-img
                  v-if="ProfilePicture !== ''"
                  :src="ProfilePicture"
                  height="140px"
                  class="rounded"
                  contain
                ></v-img>

                <v-img
                  :src="user.avatar"
                  height="140px"
                  class="rounded"
                  contain
                  v-else-if="user.avatar !== null"
                />

                <v-img
                  src="../../../../assets/profile.jpg"
                  height="140px"
                  class="rounded"
                  contain
                  v-else
                />
              </v-avatar>
            </div>

            <v-card-subtitle class="text-center">
              Attach a passport Size colored photo
            </v-card-subtitle>

            <v-card-actions>
              <v-file-input
                outlined
                dense
                ref="Image"
                type="file"
                v-model="formData.Image"
                @change="attachProfilePicture"
                accept="image/png, image/jpeg"
                append-icon="mdi-paperclip"
              >
                <template v-slot:label>
                  <div>Attach Photo <span class="red--text">*</span></div>
                </template>
              </v-file-input>
            </v-card-actions>
          </v-card>
          <v-divider />
          <cvBio
            :rules="rules"
            :profile="bio[0]"
            @editFile="editFile"
            @saveFile="saveFile"
          />
        </v-col>

        <v-divider vertical />

        <v-col md="8" sm="12">
          <v-form v-model="isValid" ref="bioForm">
            <v-row dense>
              <v-col md="4" cols="12">
                <v-text-field
                  outlined
                  dense
                  v-model="formData.firstName"
                  :rules="rules.Field"
                  ref="FirstName"
                >
                  <template v-slot:label>
                    <div>First Name <span class="red--text">*</span></div>
                  </template>
                </v-text-field>
              </v-col>

              <v-col md="4">
                <v-text-field
                  outlined
                  dense
                  v-model="formData.secondName"
                  ref="SecondName"
                >
                  <template v-slot:label>
                    <div>Middle Names <span class="red--text">*</span></div>
                  </template>
                </v-text-field>
              </v-col>

              <v-col md="4" cols="12">
                <v-text-field
                  outlined
                  dense
                  v-model="formData.lastName"
                  :rules="rules.Field"
                  ref="LastName"
                >
                  <template v-slot:label>
                    <div>Last Name <span class="red--text">*</span></div>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" md="4"
                ><v-select
                  dense
                  outlined
                  :items="marital"
                  v-model="formData.maritalStatus"
                  ref="maritalStatus"
                  item-value="code"
                  item-text="description"
                >
                  <template v-slot:label>
                    <div>Marital Status</div>
                  </template>
                </v-select>
              </v-col>

              <v-col md="4" cols="12">
                <v-text-field
                  outlined
                  dense
                  v-model="formData.idNo"
                  :rules="rules.Field"
                  ref="idNo"
                >
                  <template v-slot:label>
                    <div>ID No <span class="red--text">*</span></div>
                  </template>
                </v-text-field>
              </v-col>

              <v-col md="4" cols="12" v-if="user.provider !== 'linkedin'">
                <v-text-field
                  outlined
                  dense
                  v-model="formData.linkedInURL"
                  ref="linkedInURL"
                >
                  <template v-slot:label>
                    <div>LinkedIn URL <span class="red--text"></span></div>
                  </template>
                </v-text-field>
              </v-col>

              <v-col md="4" cols="12">
                <v-select
                  dense
                  outlined
                  :items="titles"
                  v-model="formData.title"
                  :rules="rules.Field"
                  ref="Title"
                  item-value="title"
                  item-text="titleDescription"
                >
                  <template v-slot:label>
                    <div>Title <span class="red--text">*</span></div>
                  </template>
                </v-select>
              </v-col>

              <v-col md="4" cols="12">
                <v-select
                  dense
                  outlined
                  :items="['Male', 'Female', 'Other']"
                  v-model="formData.gender"
                  :rules="rules.Field"
                  ref="Gender"
                >
                  <template v-slot:label>
                    <div>Gender <span class="red--text">*</span></div>
                  </template>
                </v-select>
              </v-col>

              <v-col md="4" cols="12">
                <v-text-field
                  outlined
                  dense
                  type="date"
                  v-model="formData.dateOfBirth"
                  :rules="rules.Field"
                  ref="DateOfBirth"
                >
                  <template v-slot:label>
                    <div>Date of Birth <span class="red--text">*</span></div>
                  </template>
                </v-text-field>
              </v-col>

              <v-col md="6" cols="12">
                <v-textarea
                  outlined
                  dense
                  rows="2"
                  v-model="formData.professionalSummary"
                  :rules="rules.Field"
                  ref="professionalSummary"
                >
                  <template v-slot:label>
                    <div>
                      Professional Summary<span class="red--text">*</span>
                    </div>
                  </template>
                </v-textarea>
              </v-col>

              <v-col md="6" cols="12">
                <v-switch
                  v-model="formData.anyFormOfDisability"
                  ref="Disability"
                  inset
                  label="Any form of disability"
                  class="mt-0"
                />

                <v-textarea
                  v-if="formData.anyFormOfDisability && editable"
                  v-model="formData.disabilityDescription"
                  :rules="rules.DisabilityDescription"
                  ref="DisabilityDescription"
                  outlined
                  label="Specify disability"
                  rows="2"
                >
                  <template v-slot:label>
                    <div>
                      Specify disability <span class="red--text">*</span>
                    </div>
                  </template>
                </v-textarea>
              </v-col>
            </v-row>
          </v-form>
          <v-divider />
          <Contact ref="myChild" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <!-- <v-card-actions class="profile--actions">
      <v-spacer />
      <v-btn color="primary" @click="saveProfile" v-if="!checkBio()">
        <v-icon class="mx-2">mdi-content-save-outline</v-icon>
        Save
      </v-btn>
      <v-btn color="primary" @click="editProfile" v-if="checkBio()">
        <v-icon class="mx-2">mdi-content-save-outline</v-icon>
        Save
      </v-btn>
    </v-card-actions> -->

    <Spinner />
  </v-card>
</template>

<script>
import { AuthService } from "../../../auth";
import Spinner from "@/plugins/loader/views/Spinner";
import Contact from "../contact/Contact.vue";
import cvBio from "./cvBio.vue";
import { helper } from "@/utils";

export default {
  name: "Bio",
  components: { Spinner, Contact, cvBio },
  data: function () {
    return {
      image_url: "",
      editable: true,
      edit: false,
      isValid: false,
      formData: {
        Image: undefined,
        address: "",
        anyFormOfDisability: false,
        city: "",
        countryCode: "",
        dateOfBirth: "",
        disabilityDescription: "",
        email: "",
        firstName: "",
        gender: "",
        idNo: "",
        lastName: "",
        linkedInURL: "",
        maritalStatus: "",
        nationality: "KE",
        professionalSummary: "",
        profileID: "",
        searchName: "",
        secondName: "",
        title: "",
        base64ProfilePic: undefined,
      },
      getImageinbs64: "",
    };
  },

  mounted() {
    this.$store.dispatch("Profile/getAttachment", this.user.profileID);
  },

  computed: {
    rules() {
      return {
        profImage: [
          (v) => {
            return this.editable &&
              AuthService.user.profile &&
              !AuthService.user.profile.picture
              ? !!v || "Profile Picture is required"
              : true;
          },
          (v) => {
            return this.editable && !!v
              ? v.size < 10000000 || "Attachment should be less than 10MB"
              : true;
          },
          (v) => {
            return this.editable && !!v
              ? v.type.includes("image") || "Attachment must be an image"
              : true;
          },
        ],
        Field: [(v) => !!v || "Field is required"],
        cvAttachment: [
          (v) => !!v || "CV is required",
          (v) =>
            v ? v.size < 5000000 || "Attachment should be less than 5MB" : true,
        ],
      };
    },
    user() {
      return AuthService.user;
    },
    bio() {
      return this.$store.getters["Profile/bioGetters"]("bio");
    },
    marital() {
      return this.$store.getters["Profile/bioGetters"]("maritalStatus");
    },
    titles() {
      return this.$store.getters["Profile/bioGetters"]("titles");
    },
    sectors() {
      return this.$store.getters["Profile/bioGetters"]("sectors");
    },

    ProfilePicture() {
      let profile = this.bio[0];

      if (this.image_url) return this.image_url;

      return profile
        ? profile.base64ProfilePic
          ? `data:image/jpeg;base64,${profile.base64ProfilePic}` ||
            `data:image/png;base64,${profile.base64ProfilePic}`
          : ""
        : "";
    },

    profImage() {
      return `data:image/jpeg;base64,${this.bio[0].base64ProfilePic}`;
    },

    cvAttachment() {
      const attachments =
        this.$store.getters["Profile/attachmentGetters"]("attachments");
      return attachments
        ? attachments.find((attachment) => attachment.documentCode === "CV")
        : null;
    },
  },

  methods: {
    saveProfile: function () {
      if (!this.isValid) {
        this.$refs.bioForm.validate();
      } else {
        const data = { ...this.formData };
        data.Image = data.base64ProfilePic;
        data.profileID = this.user.profileID;
        this.$store.dispatch("Profile/saveBio", data);
        this.$refs.myChild.saveContact();
      }
    },
    convertTobase64: async function (url) {
      const data = await fetch(url);
      const blob = await data.blob();
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => {
        const base64data = reader.result;
        console.log(base64data);
        const strImage = base64data.replace(/^data:image\/[a-z]+;base64,/, "");
        this.formData.base64ProfilePic = strImage;
        return base64data;
      };
    },

    attachProfilePicture: function () {
      this["image_url"] = URL.createObjectURL(this.formData["Image"]);
      this.getImageinbs64 = this.convertTobase64(this.image_url);
    },
    redirect() {
      this.$router.push({
        name: "Home",
      });
    },
    editProfile: function () {
      if (!this.isValid) {
        this.$refs.bioForm.validate();
      } else if (this.cvAttachment === undefined) {
        Event.$emit("ApiError", "Please upload your CV");
      } else {
        const data = { ...this.formData };
        data.Image = data.base64ProfilePic;
        data.profileID = this.user.profileID;
        this.$store.dispatch("Profile/saveBio", data);
        this.$refs.myChild.updateProfileContact();
      }
    },
    checkBio() {
      if (this.bio.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    saveFile: function (data) {
      this.$store.dispatch(`Profile/saveAttachment`, {
        profileID: this.user.profileID,
        attachData: helper.prepareFormData(data),
      });
    },
    editFile: function (data) {
      this.$store.dispatch(`Profile/editAttachment`, {
        profileID: this.user.profileID,
        attachData: helper.prepareFormData(data),
      });
    },
  },

  watch: {
    bio: {
      handler: function () {
        if (this.bio.length > 0) {
          this.formData = { ...this.bio[0] };
          if (this.formData.dateOfBirth === "0001-01-01") {
            this.formData.dateOfBirth = "";
            this.formData.idNo = "";
          }
          if (this.formData.firstName === "" && this.user.firstName !== "") {
            this.formData.firstName = this.user.firstName;
            this.formData.lastName = this.user.lastName;
          }
        }
      },
    },
  },
};
</script>

<style>
.transform {
  text-transform: uppercase;
}
.profile--title {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
}

.profile--actions {
  position: sticky;
  bottom: 0;
  z-index: 1;
  background-color: #fff;
}
</style>
