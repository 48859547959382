import constants from "./profileConstants";
import { call } from "@/service";
import academicStore from "./components/academic/academicStore";
import bioStore from "./components/bio/bioStore";
import contactStore from "./components/contact/contactStore";
import Experiencestore from "./components/experience/ExperienceStore";
import refereeStore from "./components/referees/refereeStore";
import attachmentStore from "./components/attachments/attachmentStore";

export default {
  namespaced: true,
  modules: {
    academicStore,
    bioStore,
    contactStore,
    Experiencestore,
    refereeStore,
    attachmentStore,
  },
  state: {
    jobs: [],
    categories: [],
    profile: {},
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
  },
  getters: {
    alert: (state) => state.alert,

    profileGetters: (state) => (val) => state[val],
  },
  actions: {
    getJobs: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });

      call("get", constants.jobs)
        .then((res) => {
          commit("MUTATE", { state: "jobs", value: res.data.data });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    getCategories: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });

      call("get", constants.categories)
        .then((res) => {
          commit("MUTATE", { state: "categories", value: res.data.data });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    getProfile: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });

      call("get", constants.profile)
        .then((res) => {
          commit("MUTATE", { state: "profile", value: res.data.data.shift() });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    getApplication: ({ commit }, payload) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constants.GET_APPLICATION(payload))
        .then((res) => {
          commit("MUTATE", {
            state: "application",
            value: res.data.data.shift(),
          });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
  },
};
