<template>
  <v-card class="mx-auto" flat>
    <v-card-title> Review Your Application </v-card-title>
    <v-card-text>
      <v-card flat class="mx-auto">
        <v-list dense class="mt-n5">
          <v-list-item dense>
            <v-list-item-content>
              <v-list-item-title class="title">Applied For</v-list-item-title>
              <v-list-item-subtitle
                >{{ job.fullTitle }} -
                <span class="primary--text"
                  >{{ getCategory(job.category) }}
                </span>
                -
                <span class="primary--text">{{
                  job.contractType
                }}</span></v-list-item-subtitle
              >
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                color="primary"
                class="text-capitalize"
                @click="$route.back()"
                text
              >
                Update
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
      <v-divider />
      <v-card flat class="mx-auto">
        <v-card-title class="text-body-1">
          Personal Details
          <v-spacer />
          <v-btn
            color="primary"
            class="text-capitalize"
            text
            @click="backToStep(0)"
          >
            edit
          </v-btn>
        </v-card-title>
        <v-list dense class="mt-n5">
          <v-list-item dense>
            <v-list-item-avatar size="80">
              <v-img
                :src="ProfilePicture"
                height="100px"
                class="rounded"
                contain
              ></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="title">
                {{ profile.firstName }} {{ profile.middleName }}
                {{ profile.lastName }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ profile.email }} - {{ profile.phoneNo }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
      <v-divider />
      <v-card flat class="mx-auto">
        <v-card-title class="text-body-1">
          Academics
          <v-spacer />
          <v-btn
            color="primary"
            class="text-capitalize"
            text
            @click="backToStep(1)"
          >
            edit
          </v-btn>
        </v-card-title>

        <v-list dense class="mt-n5">
          <v-list-item
            v-for="(qualification, i) in qualifications"
            :key="i"
            :value="qualification"
          >
            <v-list-item-content>
              <v-list-item-title class="text-body-2">
                {{ qualification.institution }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ qualification.areaOfSpecialization }}:
                {{ qualification.type }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
      <v-divider />
      <v-card flat class="mx-auto">
        <v-card-title class="text-body-1">
          Experience
          <v-spacer />
          <v-btn
            color="primary"
            class="text-capitalize"
            text
            @click="backToStep(2)"
          >
            edit
          </v-btn>
        </v-card-title>

        <v-list dense class="mt-n5">
          <v-list-item
            v-for="(experience, i) in experiences"
            :key="i"
            :value="experience"
          >
            <v-list-item-content>
              <v-list-item-title class="text-body-2">
                {{ experience.positionHeld }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ experience.nameOfFirm }} ({{
                  experience.fromDate
                }}
                &nbsp;to&nbsp; {{ experience.toDate }})
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
      <v-divider />
      <v-card flat class="mx-auto">
        <v-card-title class="text-body-1">
          Uploaded Documents
          <v-btn
            color="primary"
            class="text-capitalize"
            v-if="isDocuments"
            @click="backToStep(1)"
            text
          >
            edit
          </v-btn>
        </v-card-title>
        <v-list dense class="mt-n5">
          <v-list-item v-for="(file, f) in documents" :key="f" :value="file">
            <v-list-item-content>
              <v-list-item-title class="text-body-2">
                {{ file.documentCode }}
              </v-list-item-title>
              <!-- <v-list-item-subtitle
                >Existing {{ file.documentCode }}:
                {{ file.id }}</v-list-item-subtitle
              > -->
            </v-list-item-content>
            <v-list-item-action>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    icon
                    v-on="on"
                    v-bind="attrs"
                    @click="downloadFile(file.attachment)"
                  >
                    <v-icon class="mx-2">mdi-download</v-icon>
                  </v-btn>
                </template>
                <span>Download Existing {{ file.documentCode }}</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
    </v-card-text>
  </v-card>
</template>
<script>
import FileMixin from "@/mixin/FileMixin";
export default {
  name: "reviewApplication",
  mixins: [FileMixin],
  props: {
    job: {
      type: Object,
      required: true,
    },
    documents: {
      type: Array,
      required: true,
    },
    profile: {
      type: Object,
      required: true,
    },
    isDocuments: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      //
    };
  },
  mounted() {
    this.$store.dispatch("Home/getCategories");
    this.$store.dispatch("Profile/getAcademic");
    this.$store.dispatch("Profile/getExperience");
  },
  computed: {
    categories: function () {
      return this.$store.getters["Home/homeGetters"]("categories");
    },
    ProfilePicture() {
      return this.profile
        ? this.profile.base64ProfilePic
          ? `data:image/jpeg;base64,${this.profile.base64ProfilePic}` ||
            `data:image/png;base64,${this.profile.base64ProfilePic}`
          : ""
        : "";
    },
    qualifications() {
      return this.$store.getters["Profile/academicGetters"]("academic");
    },
    experiences() {
      return this.$store.getters["Profile/experienceGetters"]("experience");
    },
  },
  methods: {
    getCategory(val) {
      if (val) {
        const data = this.categories.filter((d) => d.code === val);
        return data[0].description;
      } else {
        return "";
      }
    },
    backToStep(step) {
      this.$emit("backToStep", step);
    },
  },
};
</script>
