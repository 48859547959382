<template>
  <router-view />
</template>
<script>
import { appName } from "./environment";
export default {
  name: "App",
  mounted() {
    document.title = appName;
  },
};
</script>