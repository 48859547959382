<template>
  <v-app>
    <v-app-bar app>
      <Logo />
      <v-spacer />

      <v-btn
        color="primary"
        v-if="!auth.check()"
        text
        :to="{ name: 'Login' }"
        class="mx-2"
      >
        Login
      </v-btn>

      <profileHeader class="" v-else />
    </v-app-bar>

    <v-main class="background">
      <router-view />
    </v-main>

    <loader />

    <app-footer />

    <app-alert />
  </v-app>
</template>

<script>
import AppFooter from "@/components/app-footer";
import { AuthService } from "@/modules/auth";
import Logo from "@/components/Logo";
import profileHeader from "@/modules/home/components/profileHeader.vue";
export default {
  name: "home",
  components: { AppFooter, Logo, profileHeader },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Home/getCategories");
      v.$store.dispatch("Home/getJobs");
      v.$store.dispatch("Landing/getCompanyInfo");
      v.$store.dispatch("Profile/getProfile");
    });
  },

  computed: {
    auth() {
      return AuthService;
    },
  },
};
</script>

<style scoped></style>
