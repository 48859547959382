<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>

    <spinner />

    <app-alert />

    <app-footer />

    <confirmation />
  </v-app>
</template>

<script>
import Spinner from "@/plugins/loader/views/Spinner";
import AuthService from "@/modules/auth/authService";
import AppFooter from "@/components/app-footer";

export default {
  name: "authLayout",
  components: { AppFooter, Spinner },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (AuthService.check()) {
        if (v.$route.params.code) {
          v.$router.push({
            name: "uploadCV",
            params: {
              code: v.$route.params.code,
            },
          });
        } else {
          v.$router.push({
            name: "Home",
          });
        }
      }
    });
  },

  computed: {
    auth() {
      return AuthService;
    },

    company() {
      return this.$store.getters["Home/company"];
    },
  },
};
</script>

<style scoped></style>
