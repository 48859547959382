import call from "../../../../service/http";
import constants from "./attachmentConstants";

export default {
  state: {
    attachments: [],
    attachmentDocTypes: [],
    singleAttachment: {},
    application: {},
    cv: {},
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
  },
  getters: {
    attachmentGetters: (state) => (val) => state[val],
  },
  actions: {
    getAttachment: ({ commit }, payload) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constants.GETATTACH(payload))
        .then((res) => {
          commit("MUTATE", { state: "attachments", value: res.data.data });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    saveAttachment: ({ commit, dispatch }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.attachment, data.attachData)
        .then(() => {
          dispatch("getAttachment", data.profileID);
          // Event.$emit("redirectToAttachment");
          Event.$emit("ApiSuccess", "Attachment Added Successfully")
        })
        .catch((err) => {
          console.log(err);
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    editAttachment: ({ commit, dispatch }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.updateAttachment, data.attachData)
        .then(() => {
          dispatch("getAttachment", data.profileID);
          Event.$emit("ApiSuccess", "Attachment Updated Successfully");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    deleteAttachment: ({ commit, dispatch }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.delAttachment, data.attachData)
        .then(() => {
          dispatch("getAttachment", data.profileID);
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    getAttachmentDocTypes: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });

      call("get", constants.attachmentDocTypes)
        .then((res) => {
          commit("MUTATE", {
            state: "attachmentDocTypes",
            value: res.data.data,
          });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    getOneAttachment({ commit }, payload) {
      commit("SET_LOADING", true, { root: true });
      call("get", constants.SINGLE(payload))
        .then((res) => {
          commit("MUTATE", {
            state: "singleAttachment",
            value: res.data.data[0],
          });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    directAttachment: ({ commit, dispatch }, data) => {
      console.log(data);

      commit("SET_LOADING", true, { root: true });
      call("post", constants.attachment, data.attachData)
        .then(() => {
          dispatch("Home/createApplication", data.applData, { root: true });
          // dispatch("saveBio", data.profileData);
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    saveMultipleAttachments: ({ commit, dispatch }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.attachMultiple, data.attachData)
        .then(() => {
          Event.$emit("ApiSuccess", "Attachments Added Successfully");
          Event.$emit("goNextStep");
          dispatch("getAttachment", data.profileID);
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
  },
};
