import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import auth from "./modules/auth";
import home from "./modules/home";
import profile from "./modules/profile";
import landing from "./modules/landing";
import appAlert from "./plugins/appAlert";
import confirmation from "./plugins/confirmtion";
import listener from "@/mixin/listener";
import loader from "./plugins/loader";
import router from "./router";
import { filters } from "./utils";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.config.productionTip = false;

window.Event = new Vue();

const options = {
  store,
  router,
};

Vue.use(appAlert);
Vue.use(confirmation);
Vue.use(loader);
Vue.use(Toast);
Vue.use(auth, options);
Vue.use(home, options);
Vue.use(profile, options);
Vue.use(landing, options);

Vue.use(VueTelInputVuetify, {
  vuetify,
});

/* Register Filters */
filters.forEach((f) => {
  Vue.filter(f.name, f.execute);
});

new Vue({
  vuetify,
  store,
  router,
  mixins: [listener],
  render: (h) => h(App),
}).$mount("#app");
