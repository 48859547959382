<template>
  <v-container>
    <v-card flat>
      <v-toolbar flat dense>
        <v-tabs v-model="tabs" align-with-title>
          <v-tab
            v-for="(tab, i) in allApplicationsStats"
            :key="i"
            :class="`${getStatusColor(tab.status).color}--text ml-n2`"
          >
            <v-badge :content="tab.count" color="primary" class="mt-2">
              {{ getStatusColor(tab.status).wording }}
            </v-badge>
          </v-tab>
        </v-tabs>

        <v-btn text small :to="{ name: 'Home' }">
          <v-icon left>mdi-arrow-left</v-icon>
          back
        </v-btn>
      </v-toolbar>

      <v-divider />

      <v-card-text>
        <v-row>
          <v-col
            :cols="$vuetify.breakpoint.smAndDown ? '12' : '6'"
            v-if="applications.length !== 0"
          >
            <v-tabs-items v-model="tabs">
              <v-tab-item v-for="(tab, i) in allApplicationsStats" :key="i">
                <v-list>
                  <v-list-item dense class="mt-n5">
                    <div class="text-body-1 font-weight-bold">
                      {{ tab.status }} Applications
                    </div>
                  </v-list-item>
                  <v-list-item-group v-model="selectedJob" color="primary">
                    <template>
                      <v-list-item
                        three-line
                        v-for="(application, i) in tab.list"
                        :key="i"
                      >
                        <template v-slot:default>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{
                                application.jobVacancies
                                  ? application.jobVacancies.fullTitle
                                  : ""
                              }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{
                                application.jobVacancies
                                  ? application.jobVacancies.description
                                  : ""
                              }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle class="caption">
                              <!-- 2 Days Ago -->
                            </v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-list-item-action-text class="mb-5 primary--text">
                              <v-btn
                                class="primary mx-2 text-body-2 text-capitalize"
                                v-if="application.status === 'Offer'"
                                @click="viewJobOffer(application)"
                              >
                                View Job Offer
                              </v-btn>
                              <v-chip class="primary--text">{{
                                application.status
                              }}</v-chip>
                            </v-list-item-action-text>
                          </v-list-item-action>
                        </template>
                      </v-list-item>
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
          <v-col
            :cols="$vuetify.breakpoint.smAndDown ? '12' : '6'"
            v-if="applications.length === 0"
          >
            <v-skeleton-loader
              type="card-heading, list-item-two-line, list-item-two-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
          <v-divider vertical />
          <v-col :cols="$vuetify.breakpoint.smAndDown ? '12' : '6'">
            <selectedJobCard
              :specificJob="selectedApplication"
              :isApply="false"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import selectedJobCard from "./selectedJobCard";
import AuthService from "@/modules/auth/authService";
import { helper } from "../../../utils";
export default {
  name: "my-applications",
  components: {
    selectedJobCard,
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Home/getApplications", v.user.profileID);
      v.$store.dispatch("Home/getAllJobs");
      v.$store.dispatch("Profile/getBio");
    });
  },
  data() {
    return {
      selectedJob: 0,
      tabs: null,
    };
  },

  computed: {
    user() {
      return AuthService.user;
    },
    bio() {
      return this.$store.getters["Profile/bioGetters"]("bio");
    },

    jobs: function () {
      return this.$store.getters["Home/homeGetters"]("allJobs");
    },
    allApplicationsStats() {
      return this.applications
        ? [
            {
              status: "All",
              count: this.applications.length,
              list: this.applications,
            },
            {
              status: "Review",
              count: this.applications.filter(
                (application) => application.status === "Review"
              ).length,
              list: this.applications.filter(
                (application) => application.status === "Review"
              ),
            },
            {
              status: "Submitted",
              count: this.applications.filter(
                (application) => application.status === "Submitted"
              ).length,
              list: this.applications.filter(
                (application) => application.status === "Submitted"
              ),
            },
            {
              status: "Offer",
              count: this.applications.filter(
                (application) => application.status === "Offer"
              ).length,
              list: this.applications.filter(
                (application) => application.status === "Offer"
              ),
            },
            {
              status: "Declined",
              count: this.applications.filter(
                (application) => application.status === "Declined"
              ).length,
              list: this.applications.filter(
                (application) => application.status === "Declined"
              ),
            },
            {
              status: "Accepted",
              count: this.applications.filter(
                (application) => application.status === "Accepted"
              ).length,
              list: this.applications.filter(
                (application) => application.status === "Accepted"
              ),
            },
          ]
        : [];
    },
    applications() {
      return this.$store.getters["Home/homeGetters"]("applications");
    },
    selectedApplication() {
      return this.applications
        ? this.applications[this.selectedJob]
          ? this.applications[this.selectedJob].jobVacancies
          : {}
        : {};
    },
  },
  methods: {
    getStatusColor(status) {
      return helper.status(status);
    },
    viewJobOffer: function (application) {
      this.$router.push({
        name: "JobOffer",
        params: { code: application.no },
      });
    },
  },
};
</script>

<style scoped></style>
