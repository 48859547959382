<template>
  <v-card class="mx-auto" flat>
    <div class="text-body-1 font-weight-medium mt-2 ml-2">Attach Your CV</div>
    <v-card-actions>
      <v-btn
        color="primary"
        v-if="cvAttachment"
        class="mt-2"
        block
        @click="viewAttachment()"
      >
        <v-icon>mdi-file-pdf</v-icon>
        Preview CV Attachment
      </v-btn>
    </v-card-actions>
    <v-card-actions>
      <v-file-input
        accept="application/pdf"
        :rules="rules.cvAttachment"
        dense
        prepend-icon="mdi-paperclip"
        outlined
        persistent-placeholder
        placeholder="Attach CV"
        @change="uploadFile"
        v-model="formData.attachment"
        ><template v-slot:label>
          <div>
            Attachment
            <span class="red--text">*</span>
          </div>
        </template></v-file-input
      >
    </v-card-actions>
    <v-dialog
      v-model="viewAttachmentDialog"
      v-if="cvAttachment"
      persistent
      width="80%"
    >
      <v-card class="mx-auto">
        <v-card-title class="file--title">
          <div class="text-lg-h5 font-weight-bold mr-2">CV Attachment</div>
          <v-btn
            color="primary"
            class="mt-2"
            @click="downloadFile(cvAttachment.attachment)"
          >
            Download
          </v-btn>
          <v-spacer />
          <v-btn icon @click="viewAttachmentDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <pdf :src="`data:application/pdf;base64,${cvAttachment.attachment}`" />
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import pdf from "vue-pdf";
import FileMixin from "@/mixin/FileMixin";
export default {
  name: "cvBio",
  mixins: [FileMixin],
  components: {
    pdf,
  },
  props: {
    rules: {
      type: Object,
      default: () => ({}),
    },
    profile: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      formData: {
        documentCode: "CV",
        relatedRecordId: "",
        attachment: undefined,
      },
      viewAttachmentDialog: false,
    };
  },
  computed: {
    cvAttachment() {
      const attachments =
        this.$store.getters["Profile/attachmentGetters"]("attachments");
      return attachments
        ? attachments.find((attachment) => attachment.documentCode === "CV")
        : undefined;
    },
  },
  methods: {
    async uploadFile(file) {
      this.formData.attachment = file;
      this.formData.relatedRecordId = this.profile.id;
      if (this.cvAttachment) {
        this.$emit("editFile", {
          ...this.formData,
          id: this.cvAttachment.id,
        });
      } else {
        this.$emit("saveFile", { ...this.formData });
      }
    },
    viewAttachment() {
      this.viewAttachmentDialog = true;
    },
  },
};
</script>
<style>
.file--title {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
}
</style>
