<template>
  <v-container class="mb-5">
    <v-card tile flat>
      <v-card-title>
        <v-btn text small @click="$router.back()">
          <v-icon left>mdi-arrow-left</v-icon>
          back
        </v-btn>
        <v-spacer />
        <!-- <profileHeader /> -->
      </v-card-title>

      <v-divider />

      <v-card-text>
        <h2 class="text-h3 mb-5 font-weight-bold primary--text">
          {{ singleJob ? singleJob.fullTitle : "N/A" }}
        </h2>
        <v-row no-gutters class="caption">
          <v-col cols="12" md="6"
            >Location:
            <span class="black--text font-italic font-weight-black ml-1">
              {{ singleJob ? singleJob.location : "" }}
            </span></v-col
          >

          <v-col cols="12" md="6">
            Application:<span
              class="green--text font-italic font-weight-black caption ml-1"
              >{{ getStatus(singleJob.status) }}</span
            >
          </v-col>
          <v-col cols="12" md="6" v-if="singleJob.showPayRange === true"
            >Salary: KES
            <span
              class="green--text font-italic font-weight-black caption ml-1"
            >
              {{
                singleJob ? singleJob.minAskingSalary : "" | currencyFormat
              }}-{{
                singleJob ? singleJob.maxAskingSalary : "" | currencyFormat
              }}</span
            >

            / {{ singleJob ? singleJob.payFrequency : "" }}</v-col
          >
          <v-col cols="12" md="6" v-if="singleJob.showPayRange === false"
            >Salary:<span
              class="green--text font-italic font-weight-black caption ml-1"
              >Confidential</span
            >
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-text v-if="!lockSelection">
        <v-row>
          <v-col cols="12" md="6">
            <v-card flat>
              <v-card-title class="overline">Job Requirements</v-card-title>

              <v-card-text>
                <v-list dense>
                  <v-list-item-group v-model="selectedItem">
                    <template>
                      <v-list-item
                        v-for="(req, i) in requirements"
                        three-line
                        :key="i"
                        :to="{ name: req.code }"
                        active-class="primary lighten-4"
                      >
                        <template v-slot:default>
                          <v-list-item-avatar>
                            <v-icon
                              x-large
                              :color="req.complete ? 'success' : 'warning'"
                              >{{
                                `${
                                  req.complete
                                    ? "mdi-check-circle"
                                    : "mdi-alert-circle"
                                }`
                              }}</v-icon
                            >
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              req.name
                            }}</v-list-item-title>
                            <v-list-item-subtitle>
                              {{ req.description }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              <p class="red--text font-italic caption">
                                <v-icon color="red" small>{{
                                  req.icon
                                }}</v-icon>
                                {{ req.alert }}
                              </p>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>

          <v-divider vertical />

          <v-col cols="12" md="">
            <router-view />
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer />
          <v-btn
            large
            :disabled="
              this.hasAttachment === false || this.attachments.length === 0
            "
            color="primary"
            :to="{ name: 'My Applications' }"
          >
            continue
          </v-btn>
        </v-card-actions>
      </v-card-text>
      <v-card-text v-if="lockSelection">
        <v-skeleton-loader
          type="card-heading, list-item-two-line, list-item-two-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line"
        ></v-skeleton-loader
      ></v-card-text>

      <v-divider />
    </v-card>
  </v-container>
</template>

<script>
import AuthService from "@/modules/auth/authService";

//
export default {
  name: "application",
  data: function () {
    return {
      type: "",
      toggle: false,
      code: "",
      selectedItem: undefined,
      lockSelection: false,
    };
  },
  components: {},
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Home/getSingleJob", v.$route.params.code);
      if (to.query.code) {
        v.$store.dispatch("Profile/getApplication", atob(to.query.code));
      }
      v.$store.dispatch("Profile/getBio");
      v.$store.dispatch("Profile/getAcademic");
      v.$store.dispatch("Profile/getExperience");
      v.$store.dispatch("Profile/getReferee");
      v.$store.dispatch("Profile/getAttachment", v.user.profileID);
      v.$store.dispatch("Landing/getCompanyInfo");
    });
  },
  // mounted() {
  //   Event.$on("redirectToQuestions", () => {
  //     this.$router.push({
  //       name: "Questions",
  //       params: { code: this.singleJob.code },
  //     });
  //   });
  // },

  computed: {
    requirements() {
      return [
        // {
        //   name: "Academic Information",
        //   description: "Add your academic/Professional Information",
        //   alert: "",
        //   code: "Academic",
        //   icon: "",
        //   complete: this.qualifications.length === 0 ? false : true,
        // },
        // {
        //   name: "Work Experience",
        //   description: "Add your Work Experience",
        //   alert: "",
        //   code: "Experience",
        //   icon: "",
        //   complete: this.experience.length === 0 ? false : true,
        // },
        // {
        //   name: "Referees",
        //   description: "Add your Contact references",
        //   alert: "",
        //   code: "Referees",
        //   icon: "",
        //   complete: this.referees.length === 0 ? false : true,
        // },
        {
          name: "Attachments",
          description: "Add attachments",
          alert: "Make sure to add all Mandatory Documents to continue ",
          code: "Attachments",
          icon: "mdi-alert",
          complete:
            this.attachments.length === 0 || this.hasAttachment === false
              ? false
              : true,
        },
      ];
    },
    bio() {
      return this.$store.getters["Profile/bioGetters"]("bio");
    },
    user() {
      return AuthService.user;
    },
    checkLoggedIn() {
      return this.user === null;
    },
    singleJob: function () {
      const singleJob = this.$store.getters["Home/homeGetters"]("singleJob");
      return singleJob ? singleJob : {};
    },
    qualifications() {
      return this.$store.getters["Profile/academicGetters"]("academic");
    },
    experience() {
      return this.$store.getters["Profile/experienceGetters"]("experience");
    },
    referees() {
      return this.$store.getters["Profile/refereeGetters"]("referees");
    },
    attachments() {
      return this.$store.getters["Profile/attachmentGetters"]("attachments");
    },
    SingleApplication: function () {
      return this.$store.getters["Profile/attachmentGetters"]("application");
    },
    routeBasedItem: function () {
      return this.selectedItem;
    },
    mandatoryDocs: function () {
      const singleJob = this.$store.getters["Home/homeGetters"]("singleJob");
      return singleJob
        ? this.$store.getters["Home/homeGetters"]("singleJob").mandatoryDocs
        : [];
    },
    hasAttachment() {
      let hasAllAttached = true;

      this.mandatoryDocs
        ? this.mandatoryDocs.forEach((m) => {
            if (!this.attachments.find((a) => a.documentCode === m.type)) {
              hasAllAttached = false;
            }
          })
        : false;
      console.log(hasAllAttached);

      return hasAllAttached;
    },
  },
  methods: {
    openForm(val) {
      this.type = val;
      this.$router.push({
        name: val.code,
      });
    },

    checkArray() {
      const check = this.requirements.every(({ complete }) => !complete);
      return check;
    },
    getStatus(val) {
      if (val === "Open") {
        return "Ongoing";
      } else {
        return "";
      }
    },

    checkAttachments() {
      let hasAllAttached = true;

      this.mandatoryDocs.forEach((m) => {
        if (!this.attachments.find((a) => a.documentCode === m.code)) {
          hasAllAttached = false;
        }
      });

      console.log(hasAllAttached);

      return hasAllAttached;
    },
  },

  // watch: {
  //   bio: {
  //     handler: function () {
  //       if (this.bio.length > 0) {
  //         if (this.bio[0].firstName === "") {
  //           this.$router.replace({
  //             name: "updateProfile",
  //             params: { code: this.singleJob.code },
  //           });
  //         }
  //       }
  //     },
  //   },
  // },
};
</script>

<style scoped></style>
