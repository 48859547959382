<template>
  <v-dialog persistent v-model="dialog" max-width="70%">
    <v-form v-model="isValid" ref="refForm">
      <v-card>
        <v-card-title class="primary">
          <span class="text-h5 font-weight-black white--text">
            {{ params ? "Edit" : "Add" }} Referee
          </span>
        </v-card-title>

        <v-card-text class="mt-2">
          <v-container>
            <v-row dense>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  dense
                  outlined
                  v-model="formData.name"
                  :rules="rules.Field"
                  ref="name"
                >
                  <template v-slot:label>
                    <div>
                      Name
                      <span class="red--text">*</span>
                    </div>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  dense
                  outlined
                  v-model="formData.phoneNo"
                  :rules="rules.phoneNo"
                  v-on:keypress="NumbersOnly"
                  ref="phoneNo"
                >
                  <template v-slot:label>
                    <div>
                      Phone No
                      <span class="red--text">*</span>
                    </div>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  dense
                  outlined
                  v-model="formData.eMail"
                  :rules="rules.email"
                  ref="eMail"
                >
                  <template v-slot:label>
                    <div>
                      Email
                      <span class="red--text">*</span>
                    </div>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  dense
                  outlined
                  v-model="formData.address"
                  :rules="rules.Field"
                  ref="address"
                >
                  <template v-slot:label>
                    <div>
                      Address
                      <span class="red--text">*</span>
                    </div>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  dense
                  outlined
                  v-model="formData.placeOfWork"
                  :rules="rules.Field"
                  ref="placeOfWork"
                >
                  <template v-slot:label>
                    <div>
                      Place of Work
                      <span class="red--text">*</span>
                    </div>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  dense
                  outlined
                  v-model="formData.designation"
                  :rules="rules.Field"
                  ref="designation"
                >
                  <template v-slot:label>
                    <div>
                      Designation
                      <span class="red--text">*</span>
                    </div>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false"> Close </v-btn>
          <v-btn
            color="primary"
            @click="isObjectEmpty(refereeDetails) ? Save() : Edit()"
          >
            {{ params ? "Edit" : "Save" }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
    <Spinner />
  </v-dialog>
</template>

<script>
import { AuthService } from "../../../auth";
import Spinner from "@/plugins/loader/views/Spinner";

export default {
  name: "RefereeCard",
  components: { Spinner },
  props: { referreeDialog: Boolean, refereeDetails: { type: Object } },
  data: () => ({
    isValid: false,
    formData: {
      name: "",
      address: "",
      eMail: "",
      phoneNo: "",
      placeOfWork: "",
      designation: "",
    },
    searchForm: {
      search: "",
    },
  }),

  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (v.$route.params.line) {
        v.$store.dispatch("Profile/getOneReferee", v.$route.params.line);
      }
    });
  },

  computed: {
    rules() {
      return {
        Field: [(v) => !!v || "Field is required"],
        phoneNo: [
          (v) => !!v || "Field is required",
          (v) =>
            !v ||
            /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(v) ||
            "Invalid phone number format",
        ],
        email: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
      };
    },
    dialog: {
      get() {
        return this.referreeDialog;
      },
      set(val) {
        this.$emit("close-dialog", val);
      },
    },
    user() {
      return AuthService.user;
    },
    params() {
      return this.$route.params.line;
    },
  },

  methods: {
    redirect() {
      this.$router.push({
        name: "Referees",
      });
    },
    Save: function () {
      if (!this.isValid) {
        this.$refs.refForm.validate();
      } else {
        this.formData.profileID = this.user.profileID;
        //console.log(this.formData);
        this.$store.dispatch("Profile/saveReferee", this.formData);
      }
    },
    Edit: function () {
      this.formData.profileID = this.user.profileID;
      const data = this.formData;
      this.$store.dispatch("Profile/editReferee", data);
      console.log(data);
    },
    NumbersOnly(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isObjectEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
  },

  watch: {
    refereeDetails: {
      handler: function () {
        this.formData = { ...this.refereeDetails };
      },
    },
  },
};
</script>

<style scoped></style>
