<template>
  <v-dialog persistent v-model="dialog" max-width="60%">
    <v-form v-model="isValid" ref="expForm">
      <v-card>
        <v-card-title class="primary">
          <span class="text-h5 font-weight-black white--text">
            {{ !isObjectEmpty ? "Edit" : "Add" }} Experience
          </span>
        </v-card-title>

        <v-card-text class="mt-10">
          <v-container>
            <v-row dense>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  dense
                  outlined
                  v-model="formData.nameOfFirm"
                  :rules="rules.Field"
                  ref="nameOfFirm"
                >
                  <template v-slot:label>
                    <div>
                      Employer
                      <span class="red--text">*</span>
                    </div>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-select
                  dense
                  outlined
                  :items="sectors"
                  v-model="formData.sector"
                  :rules="rules.Field"
                  ref="Sector"
                  item-value="code"
                  item-text="description"
                >
                  <template v-slot:label>
                    <div>
                      Sector
                      <span class="red--text">*</span>
                    </div>
                  </template>
                </v-select>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  dense
                  outlined
                  v-model="formData.positionHeld"
                  :rules="rules.Field"
                  ref="positionHeld"
                >
                  <template v-slot:label>
                    <div>
                      Position Held
                      <span class="red--text">*</span>
                    </div>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  dense
                  outlined
                  type="date"
                  v-model="formData.fromDate"
                  :rules="rules.FromDate"
                  ref="FromDate"
                >
                  <template v-slot:label>
                    <div>
                      From Date
                      <span class="red--text">*</span>
                    </div>
                  </template>
                </v-text-field>
              </v-col>

              <v-col v-if="!current" cols="12" sm="6" md="4">
                <v-text-field
                  dense
                  outlined
                  type="date"
                  v-model="formData.toDate"
                  :rules="rules.ToDate"
                  ref="ToDate"
                >
                  <template v-slot:label>
                    <div>
                      To Date
                      <span v-if="!current" class="red--text">*</span>
                    </div>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-switch
                  inset
                  v-model="current"
                  label="Current"
                  class="mt-0 ml-1"
                />
              </v-col>

              <v-col cols="12" sm="6" md="12">
                <v-textarea
                  dense
                  outlined
                  v-model="formData.responsibilitiesDescription"
                  :rules="rules.Field"
                  ref="Field"
                  rows="3"
                >
                  <template v-slot:label>
                    <div>
                      Key Responsibilities
                      <span class="red--text">*</span>
                    </div>
                  </template>
                </v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false"> Close </v-btn>
          <v-btn color="primary" @click="!isObjectEmpty ? Edit() : Save()">
            {{ !isObjectEmpty ? "Edit" : "Save" }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
    <Spinner />
  </v-dialog>
</template>

<script>
import { AuthService } from "../../../auth";

import Spinner from "@/plugins/loader/views/Spinner";
export default {
  name: "ExperienceCard",
  components: { Spinner },
  props: {
    experienceDialog: Boolean,
    experienceDetails: { type: Object },
  },
  beforeRouteEnter(to, from, next) {
    next(() => {});
  },
  mounted() {
    this.$store.dispatch("Profile/getSectors");
  },

  data: () => ({
    current: false,
    isValid: false,
    formData: {
      profileID: "",
      sector: "",
      nameOfFirm: "",
      fromDate: "",
      toDate: "",
      positionHeld: "",
      responsibilitiesDescription: "",
    },
  }),

  computed: {
    rules() {
      return {
        Field: [(v) => !!v || "Field is required"],
      };
    },
    user() {
      return AuthService.user;
    },
    sectors() {
      return this.$store.getters["Profile/bioGetters"]("sectors");
    },
    dialog: {
      get() {
        return this.experienceDialog;
      },
      set(val) {
        this.$emit("close-dialog", val);
      },
    },
    isObjectEmpty() {
      return Object.keys(this.experienceDetails).length === 0;
    },
  },

  methods: {
    Save: function () {
      if (!this.isValid) {
        this.$refs.expForm.validate();
      } else {
        this.formData.profileID = this.user.profileID;
        this.$store.dispatch("Profile/saveExperience", this.formData);
      }
    },
    Edit: function () {
      this.formData.profileID = this.user.profileID;
      const data = this.formData;
      this.$store.dispatch("Profile/editExperience", data);
      console.log(data);
    },
    redirectBack() {
      this.experienceDialog = false;
    },
  },
  watch: {
    experienceDetails: {
      handler: function () {
        this.formData = { ...this.experienceDetails };
        if (this.formData.toDate === "0001-01-01") {
          this.current = true;
        }
      },
    },
  },
};
</script>

<style scoped></style>
