import call from "../../../../service/http";
import contactConstants from "./contactConstants";
//

export default {
  state: {
    contact: [],
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
    SET_CONTACT_ALERT: (state, payload) => {
      state.contactAlert = payload || {
        status: "",
        message: "",
      };
    },
  },
  getters: {
    contactGetters: (state) => (val) => state[val],
  },
  actions: {
    getContact: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", contactConstants.contact)
        .then((res) => {
          commit("MUTATE", { state: "contact", value: res.data.data });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    saveContact: ({ commit, dispatch }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", contactConstants.contact, data)
        .then(() => {
          dispatch("getContact");
          Event.$emit("goNextStep");
          dispatch("Profile/getProfile", null, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    editContact: ({ commit, dispatch }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("patch", contactConstants.contact, data)
        .then(() => {
          dispatch("getContact");
          dispatch("Profile/getProfile", null, { root: true });
          Event.$emit("goNextStep");
          Event.$emit("ApiSuccess", "Successfully Edited Contacts");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    editContactDialog: ({ commit, dispatch }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("patch", contactConstants.contact, data)
        .then(() => {
          dispatch("getContact");
          dispatch("Profile/getProfile", null, { root: true });
          Event.$emit("goNextStep");
          Event.$emit("ApiSuccess", "Successfully Edited Contacts");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
  },
};
